import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "NavMenu",
})((theme) => {
  return {
    root: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      width: 0,
    },
    tabs: {
      minHeight: 48 - 4,
      width: "100%",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    tabsFlexContainer: {
      justifyContent: "space-evenly",
      gap: theme.spacing(1),
    },
    tab: {
      minHeight: 48 - 4,
      minWidth: "auto",
      padding: theme.spacing(1.5, 1),
      flexShrink: 1,
    },
  };
});

export default useStyles;
