import { commonHelpers } from "@/utils/helpers";

import { AppBar } from "@mui/material";
import AppContainer from "@/components/AppContainer";
import AppLink from "@/components/AppLink";
import MainHeaderAppBarToolbar from "@/components/MainHeaderAppBarToolbar";
import AppImage from "@/components/AppImage";
import NavMenu from "@/layouts/MainLayout/components/MainHeader/components/NavMenu";
import LanguageSelect from "@/layouts/MainLayout/components/MainHeader/components/LanguageSelect";
import Sidebar from "@/layouts/MainLayout/components/MainHeader/components/Sidebar";

import { useMemo } from "react";
import { useTranslation } from "next-i18next";
import { useAppSelector } from "@/hooks";

import MainHeaderContext from "@/layouts/MainLayout/components/MainHeader/MainHeader.context";

import useStyles from "./MainHeader.styles";

const MainHeader = () => {
  const $s_services = useAppSelector((state) => state.service.services);
  const $s_servicesLoading = useAppSelector(
    (state) => state.service.servicesLoading
  );
  const $s_servicesError = useAppSelector(
    (state) => state.service.servicesError
  );

  const serviceLoading = !!$s_servicesLoading || !!$s_servicesError;

  const { classes } = useStyles();

  const { t } = useTranslation();

  const navMenus = useMemo(() => {
    return [
      {
        code: "SERVICES",
        title: t("services"),
        href: $s_services?.[0]
          ? `/services/${commonHelpers.generateSlug(
              $s_services[0]?.ServiceLanguage?.name,
              $s_services[0]?.id
            )}`
          : "/services",
        pathnameReg: new RegExp("^/services"),
        loading: serviceLoading || $s_services.length === 0,
        items: $s_services.map((service) => {
          const slug = commonHelpers.generateSlug(
            service?.ServiceLanguage?.name,
            service?.id
          );
          return {
            title: service?.ServiceLanguage?.name,
            href: `/services/${slug}`,
            pathnameReg: new RegExp(`^/services/.+-${service?.id}`),
          };
        }),
      },
      {
        title: t("studyingInJapan"),
        href: "/studying-in-japan/school-categories",
        pathnameReg: new RegExp("^/studying-in-japan"),
        items: [
          {
            title: t("schoolCategory"),
            href: "/studying-in-japan/school-categories",
            pathnameReg: new RegExp("^/studying-in-japan/school-categories"),
          },
          {
            title: t("applicationProgress"),
            href: "/studying-in-japan/application-progress",
            pathnameReg: new RegExp("^/studying-in-japan/application-progress"),
            loading: true,
          },
          {
            title: t("studentStoriesSharing"),
            href: "/studying-in-japan/student-stories-sharing",
            pathnameReg: new RegExp(
              "^/studying-in-japan/student-stories-sharing"
            ),
            loading: true,
          },
        ],
      },
      {
        title: t("schoolsSearching"),
        href: "/schools-searching",
        pathnameReg: new RegExp("^/schools-searching"),
      },
      {
        title: t("accommodation"),
        href: "/accommodations",
        pathnameReg: new RegExp("^/accommodations"),
      },
      {
        title: t("expressAccommodation"),
        href: "/express-accommodations",
        pathnameReg: new RegExp("^/express-accommodations"),
      },
      {
        title: t("tips"),
        href: "/tips",
        pathnameReg: new RegExp("^/tips"),
      },
      {
        title: t("contactUs"),
        href: "/contact-us",
        pathnameReg: new RegExp("^/contact-us"),
      },
    ];
  }, [t, serviceLoading, $s_services]);

  return (
    <MainHeaderContext.Provider
      value={{
        navMenus,
      }}
    >
      <AppBar
        component="header"
        className={classes.root}
        elevation={0}
        color="default"
      >
        <AppContainer>
          <MainHeaderAppBarToolbar disableGutters>
            <Sidebar />

            <AppLink href="/" underline="none" hoverColor="none" display="flex">
              <AppImage
                className={classes.logoImg}
                width={500}
                height={500}
                defaultPlaceholderVariant="none"
                src="/images/logo-text.svg"
                unoptimized
              />
            </AppLink>

            <NavMenu />

            <LanguageSelect />
          </MainHeaderAppBarToolbar>
        </AppContainer>
      </AppBar>
      <MainHeaderAppBarToolbar />
    </MainHeaderContext.Provider>
  );
};

export default MainHeader;
